@import "../../../Shared/Static/scss/quantalys/_variables.scss";
@import "../../../Shared/Static/scss/bootstrap/_variables.scss";
@import "../../../Shared/Static/scss/quantalys/_mixins.scss";

//-------------------------------------------------------------------------------------------------
//-- Variables
//-------------------------------------------------------------------------------------------------

$panel-md-height: 403px;
$media-screen-md: 1200px;
$media-screen-sm: 1020px;

/* Used for video-follow-scroll class */
$zIndexVideo: 5;
$zIndexCancelIcon: 6;
$marginLeftCancelIcon: 312px;
$widthCancelicon: 18px;
$heightCancelIcon: 18px;
$sameWidthDuringScrolling: 358px;
//-------------------------------------------------------------------------------------------------
//-- style
//-------------------------------------------------------------------------------------------------
.border-top {
	margin-top: 0px;
}

.home-page {

	.dt-wrapper {
		margin-left: 10px;
	}

	.link-title {
		font-size: 13px;
		margin-left: 5px;
	}

	.actualite-benchmark-block {
		/*& > [class*='col-'].actualite-block {
			@media(min-width: $screen-md) {
				height: 870px;
			}
		}*/
		.benchmark-cat-wrapper, .benchmark-action-cat-wrapper {
			margin-bottom: 15px;
		}

		table.table {

			&.table-default {
				&.table-striped > tbody > tr:nth-of-type(odd) {
					background-color: white;
				}

				&.table-striped > tbody > tr:nth-of-type(even) {
					background-color: #F2F2F2;
				}
			}

			&#benchmark-action-cat, &#benchmark-oblig-cat {
				&.table-striped > tbody > tr:nth-of-type(odd) {
					background-color: #F2F2F2;
				}

				&.table-striped > tbody > tr:nth-of-type(even) {
					background-color: white;
				}
			}
		}
	}

	.observatoire-portefeuille-block {
		& > [class*='col-'] {
			@media(min-width: $screen-md) {
				//height: 456px;
			}
		}
	}

	.top-flop-block {
		@media(min-width: $screen-xs) and (max-width: $screen-md) {
			//height: 440px;
		}
	}

	.top-flop-consultation-block {
		& > [class*='col-'] {
			@media(min-width: $screen-md) {
				//height: $panel-md-height;
			}
		}
	}

	.video-pub-block {
		& > [class*='col-'] {
			@media(min-width: $screen-md) {
				//height: 364px;
			}
		}
	}

	.follw-quantalys {
		margin-top: 5px;
	}

	.panel-heading {
		padding-bottom: 17px;
	}

	.no-padding-left {
		padding-left: 0px;
	}

	table.table {

		&.table-default {
			&.table-striped > tbody > tr:nth-of-type(odd) {
				background-color: white;
			}

			&.table-striped > tbody > tr:nth-of-type(even) {
				background-color: #F2F2F2;
			}
		}

		img {
			cursor: pointer;
		}

		tbody > tr > td {
			font-size: 12px;

			&.data-row {
				@media(max-width: $media-screen-md ) and (min-width: $media-screen-sm) {
					font-size: 10px;
				}
			}
		}

		th.dark {
			background-color: $brand-dark;
			border-color: $brand-dark;
		}

		th.darken-header {
			background-color: darken($brand-main, 15%);
		}
		/*thead > tr > th {
            border-color: none;
        }*/
	}

	.tabs {
		margin-left: auto;
		margin-right: auto;
		width: 100%;
		padding-left: 0;
		list-style: none;
	}

	.dataTableFooter {
		display: none;
	}

	.modal {
		.modal-header {
			color: $brand-main;
			font-weight: bold;
		}

		.mergeName {
			label {
				margin-top: 7px;
			}
		}

		.modal-body {
			padding: 10px;
		}
	}

	.panel-body.reduced-padding {
		padding: 0 8px 5px 8px;
	}

	.panel-fixed-height {
		//min-height: 345px;
		//height: 100%;
		/*height: 360px;
        overflow: hidden;*/
	}
	/*.row-layout > [class*='col-'] {
        margin-bottom: 0px;
    }*/
	/*.inside-full-height, .panel-fixed-height {
        margin-bottom: 7px;
    }*/
	/*.row-layout > [class*='col-'] {
        margin-top: 0;
        margin-bottom: 0px;
    }*/
	.no-flexbox .panel.inside-full-height {
		height: auto;
		width: 100%;
	}

	.label_positive {
		color: #008000;
	}

	.label_negative {
		color: Red;
	}

	.fonddujour, .etfdujour {
		height: 220px;
		width: 100%;
	}
	/*.col-sm-12-right {
        @media(max-width: $screen-sm) and (min-width: $screen-xs) {
            padding-left: initial;
        }
    }

    .col-xs-12-right {
        @media(max-width: $screen-xs) {
            padding-left: initial;
        }
    }*/
	.no-padding {
		padding: 0;
	}

	.no-padding-left {
		padding-left: 0;
	}

	.no-padding-right {
		padding-right: 0;
	}

	.no-padding-top {
		padding-top: 0;
	}

	.no-padding-bottom {
		padding-bottom: 0;
	}

	.padding-left {
		padding-left: 15px;
	}

	.left-side {
		@media(min-width: $screen-md) {
			padding-right: 0;
		}
	}

	.right-side {
		@media(min-width: $screen-md) {
			padding-left: 0;
		}
	}

	.bottom-divider {
		position: relative;

		&:after {
			@media(min-width: $screen-md) {
				content: "";
				position: absolute;
				left: 10px;
				height: 1px;
				width: 92%;
				border-style: none;
				border-top: 1px solid #EEEEEE;
				//left: 10%;
				bottom: 5px;
			}
		}
	}

	.side-divider {
		&:after {
			content: "";
			position: absolute;
			height: 50%;
			width: 1px;
			border-style: none;
			border-left: 1px solid $brand-gray;
			left: 100%;
			top: 25%;
		}

		@media(max-width: $screen-md) {
			margin-bottom: 20px;

			&:after {
				content: "";
				position: absolute;
				height: 1px;
				width: 50%;
				border-style: none;
				border-top: 1px solid $brand-gray;
				left: 25%;
				top: 100%;
			}
		}
	}
	/*.tout-link {
        //margin-top: 10px;
        .badge-primary {
            background-color: $brand-main;
            margin-right: 5px;
        }
        a {
            text-decoration: none;
        }
    }*/
	.btn {
		//text-align: right;
		@mixin separator() {
			content: "";
			position: absolute;
			border: 1px solid white;
			height: 12px;
			width: 1px;
			top: 20%;
			//left: -10%;
		}

		.info-box {
			position: relative;
			color: white;
			font-size: 14px;

			&.info-separator {
				&:before {
					@include separator();
				}
			}

			.fa-plus-circle {
				padding-left: 10px;
			}
		}

		&.btn-sm {
			padding: 0px 6px;
		}
	}

	.legendeItemSquare {
		display: inline-block;
		width: 8px;
		height: 8px;
		background-color: gray;
		margin-left: 10px;
	}

	.legendeItemName {
		display: inline-block;
		margin: 0 4px;
		//font-size: 10px;
		color: $brand-gray;
		font-weight: $font-weight-5;
	}

	.video-block {

		.img-wrapper {
			padding-right: 0;
		}

		.desc-wrapper {
			padding-left: 0;
		}

		.video-a-la-une {
			.la-une {
				margin-bottom: 10px;

				video {
					width: 100%;
				}
			}

			.video-img {
				height: 200px;
				width: 100%;
				background-repeat: no-repeat;
				background-size: cover;
				background-position: center;
			}

			.video-desc {
				padding: 0px;
				color: black;
				margin-bottom: 15px;
			}
		}

		.video-list {
			$height: 80px;

			.video-img {
				height: $height;
				width: 100%;
				background-repeat: no-repeat;
				background-size: cover;
				background-position: center;
			}

			.video-desc {
				padding: 10px;
				height: $height;
			}
		}

		.h5 {
			color: black;
		}
	}

	.middleImg {
		padding: 10px 0px;
	}

	.gray-bck {
		background-color: $brand-gray;
		color: white;
	}

	.actualite-block {
		.panel-body .actu-desc, .panel-body .actu-title {
			a:hover, a {
				text-decoration: none;
				color: black;
			}

			.h5 {
				color: black;
			}
		}

		.img-wrapper {
			padding-right: 0;
		}

		.desc-wrapper {
			padding-left: 0;
		}

		.actu-desc {
			//padding-left: 5px;
			//height: 70px;
			margin-bottom: 20px;
			text-decoration: none;



			@media (max-width: $media-screen-md) {
				clear: both;
			}
		}

		.event-desc-wrapper {
			padding-right: 8px;
		}

		.gray-bck {
			background-color: $brand-light;
			color: black;
			padding: 13px 13px 1px 1px;
			margin-right: 5px;
			position: relative;

			@media(max-width: $screen-md) {
				padding-left: 13px;
			}
		}

		.gray-bck-before {
			&:before {
				@media(min-width: $screen-md) {
					content: "";
					position: absolute;
					background-color: $brand-light;
					left: -20px;
					top: 0;
					width: 20px;
					height: 100%;
				}
			}
		}

		.actualite-list {
			.actu-title {
				padding: 10px 0px;
			}

			.bottom-divider {
				&:after {
					left: 0;
					bottom: 0px;
				}
			}
		}

		.actualite-une {
			.video-img {
				height: 85px;
				width: 141px;
				margin: 0px 0 4px 3px;
				float: right;
				background-repeat: no-repeat;
				background-size: cover;
				background-position: center;

				@media (max-width: $media-screen-md) {
					//height: 40px;
					display: none;
					//width: 100%;
				}
			}

			.actu-title {
				/*padding-bottom: 0px;
                padding-top: 10px;*/
			}

			.actu-left {
				/*float: left;
				width: 200px;*/
				margin-bottom: 5px;

				@media (max-width: $media-screen-md) {
					width: 100%;
				}
			}
			/*@media(min-width: $screen-md) {
                padding-right: 0;
            }*/
		}
		/*.h5 {
            color: black;
        }*/
		.les-plus-lus {
		}
	}

	.benchmark-block {
		#divChart-benchmark {
			width: 100%;
			height: 220px;
		}
	}

	.observatoires-block {

		.title {
			color: $brand-main;
			font-weight: bold;
		}

		.obs-desc {
			min-height: 80px;
			padding: 10px;
		}

		.gray-bck {
			background-color: $brand-light;
			color: black;
		}

		.h5 {
			margin: 0px;
		}

		.desc-wrapper {
			// padding-left: 15px;
		}
		/*table.dataTable {
            th.no-padding.sorting {
                padding: 0;
            }
        }*/
		table.dataTable th.sorting.no-padding-left {
			padding-left: 0;
		}
	}

	.port-qt {
		& > [class*='col-'] {

			@media(min-width: $screen-md) {
				//height: 437px;
			}
		}

		.port-quantalys-block {
			.port-qt-wrapper {
				padding-left: 15px;
				padding-right: 15px;
			}

			.img-wrapper {
				width: 300px;
				margin-left: auto;
				margin-right: auto;

				img {
					width: 100%;
				}
			}
		}
	}

	.top-flop-consultation-block {
		.clt-btn {
			float: right;
			margin-right: 5px;
			margin-top: 5px;
		}
	}

	.twitter-block {
		/* Twitter Scroller */
		.qt-twitter-wrapper {
			overflow: hidden;
			height: 360px;
			position: relative;
		}

		.qt-twiter-reduice-height-wrapper {
			height: 300px;
		}

		.qt-twitter-children {
			position: absolute;
			padding-right: 20px;
			top: 0;
			bottom: 0;
			left: 0;
			right: -37px;
			overflow-y: scroll;
			//height: 510px;
		}

		.timeline .stream {
			height: 440px !important;
		}

		ol.h-feed {
			height: 440px !important;
		}

		.display-block-left {
			display: inline-block;
			float: left;
		}

		.panel-heading {
			//background-color: $brand-main;
			//padding: 5px;
			.fa-twitter {
				color: #0A50A1;
			}
		}
	}

	.linkedin-block {
		/* Twitter Scroller */
		.qt-linkedin-wrapper {
			overflow: hidden;
			height: 360px;
			position: relative;
		}

		.qt-linkedin-children {
			position: absolute;
			padding-right: 20px;
			top: 0;
			bottom: 0;
			left: 0;
			right: -53px;
			overflow-y: scroll;
			//height: 510px;
		}

		.timeline .stream {
			height: 440px !important;
		}

		ol.h-feed {
			height: 440px !important;
		}

		.display-block-left {
			display: inline-block;
			float: left;
		}

		.panel-heading {
			//background-color: $brand-main;
			//padding: 5px;
			.fa-linkedin {
				color: #0A50A1;
			}
		}
	}

	.performance-block {
		.best-perf-block {
		}
	}
}

.home-page-efpa {

	.port-qt {
		.port-quantalys-block {
			.port-qt-wrapper {
				padding-left: 15px;
				padding-right: 15px;

				.img-wrapper {
					width: 300px;
					margin-left: auto;
					margin-right: auto;

					img {
						width: 100%;
					}
				}
			}
		}
	}
}

.page-espace {
	.input-wrapper {
		padding: 5px 0px;
	}

	.btn-sm, .btn-group-sm > .btn {
		padding: 5px 10px !important;
		font-size: 12px;
		line-height: 1.5;
		border-radius: 0;
	}

	.pub-box-title {
		@media(max-width: $media-screen-sm) {
			padding: 0px 0px;
			margin-top: -12px;
		}


		.partenaire-title {
			font-size: 12px;
		}

		img {
			width: 120px;
		}
	}
}

.video-follow-scroll {
	position: fixed;
	top: 0;
	z-index: $zIndexVideo;
	width: $sameWidthDuringScrolling;
}

.video-cancel-follow-scroll {
	color: red;
	position: fixed;
	z-index: $zIndexCancelIcon;
	margin-left: $marginLeftCancelIcon;
	width: $widthCancelicon;
	height: $heightCancelIcon;
}

.hidden-element {
	visibility: hidden;
}
